.xpProgress{
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.1);
  .xpProgressPercent{
    position: absolute;
    width: 0;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.1);
    border-radius: 4px;
  }
  .item{
    .icon{
      line-height: 0.8;
      .MuiSvgIcon-root{
        font-size: 2rem;
      }
    } 
    .name{
      font-size: 0.8rem;
      font-weight: bold;
    }
    .value{
      font-size: 0.6rem;
      font-weight: bold;
    }
  }
}