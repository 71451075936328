
.game-avatar{
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;

  .MuiAvatar-root{
    position: relative;
    z-index: 3;
    border: 2px solid #666;
  }
  .xp-status.levelbar{
    position: absolute !important;
    top: 0;
    left: 50%;
    z-index: 2;
    width: 200%;
  }
  .level{
    position: absolute !important;
    bottom: 0.3em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    line-height: 0.81;
    font-weight: bold;
    color: #666;
    > span{
      display: block;
      font-size: 0.7rem;
      line-height: 1;
    }
  }
  .xpProgress{
    position: absolute !important;
    bottom: 0em;
    left: 50%;
    transform: translate(-50%, 100%);
    z-index: 3;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #666;
  }
}