.daily{
  display: block;
  margin: 0.4rem 1rem;
  clear: both;
  position: relative;

  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  padding: 0.2rem 1rem;
  padding-left: 1.6rem; // pour le stepNumber
  overflow: hidden; // pour le stepNumber

  > strong{
    font-size: 0.9rem;
  }
  .loots{
    float: right;
    margin-top: -0.2rem;
  }
  .goal{
    .xp-status{    
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.3rem;
    }
  }
  .stepNumber{
    position: absolute;
    top: 50%;
    left: -0.5rem;
    transform: translateY(-50%);

    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
    background: rgba(0,0,0,0.3);
    color: #fff;
  }
  .progress{
    height: 1rem;
    margin: 0.2rem auto;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.3);
    position: relative;
    color: #fff;
    font-size: 0.8rem;
    .progressCursor{
      height: 1rem;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background: rgba(0,0,0,0.3);

      position: absolute;
      top: 0;
      left: 0;
    }
    .progressLabel{
      padding: 0 0.5rem;
      position: relative;
      &.black{
        color: rgba(0,0,0,0.3);
      }
    }
  }

  &.achieved{
    opacity: 0.4;
    > strong{
      font-style: italic;
      text-decoration: line-through;
    }
  }
}