.calendar{
  border-collapse: collapse;
  border: 0;
  td{
    line-height: 1;
  }
  
  .monthRow{
    position: relative;
    td{
      padding-top: 0.5rem;

      position: relative;
      height: 100%;
      &:after{
        display: block;
        position: absolute;
        content: '';
        bottom: 0.3rem;
        left: 0;
        height: 1px;
        width: 100%;
        background: #ccc;
        z-index: 1;
      }

      &:first-child{
        color: #ccc;
        &:after{
          display: none;
        }
      }
    }
  }
}