.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}

.float-right{
  float: right;
}
.float-left{
  float: left;
}

.relative{
  position: relative;
}
.abs-br{
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 2;
}
.abs-tl{
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 2;
}
.abs-tr{
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 2;
}
.abs-bc{
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.m-2{
  margin: 2rem;
}
.m-05{
  margin: 0.5rem !important;
}
.mv-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mh-2{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mv-1{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mh-1{
  margin-left: 1rem;
  margin-right: 1rem;
}
.mv-05{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mh-05{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mb-1{
  margin-bottom: 1rem;
}
.mr-05{
  margin-right: 0.5rem;
}
.pr-05{
  padding-right: 0.5rem;
}
.mt-3{
  margin-top: 3rem;
}
.mt-4{
  margin-top: 4rem;
}
.p-2{
  padding: 2rem;
}
.p-1{
  padding: 1rem;
}
.p-05{
  padding: 0.5rem;
}

.d-flex-v{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.capitalize{
  text-transform: capitalize;
}


.numberOnCircle{
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  background: #333;
  color: #ccc;
  &.color-0{
    background: transparent;
    border: 1px solid #ccc;
    color: #ccc;
    font-weight: normal;
    font-style: italic;
  }
  &.color-1{
    background: #ED2F2F;
    color: #000;
  }
  &.color-2{
    background: #FF6730;
    color: #000;
  }
  &.color-3{
    background: #FFA928;
    color: #000;
  }
  &.color-4{
    background: #CECE21;
    color: #000;
  }
  &.color-5{
    background: #1ECC12;
    color: #000;
  }

  &.small{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.circle{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #eee;
  margin: 0 auto;
  .text-big &{
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}

.slick-slider{
  width: 99%;
  margin: 0 auto;
}

table{
  width: 100%;
}
td{
  vertical-align: top;
}
img{
  border: 0;
  margin: 0 auto;
}

.cursor-pointer{
  cursor: pointer;
}

.text-normal{
  font-size: 1.2rem;
}
.text-big{
  font-size: 1.6rem;
}

.xp-status,
.diamond-status{
  display: inline;
  white-space: nowrap;
  font-size: 0.8em;
  span,
  .MuiSvgIcon-root{
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
  }
  .MuiSvgIcon-root{
    font-size: 1em;
  }
  &.bar{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 1rem;
  }
  &.statusbar{
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.5em;
    padding-bottom: 3px;
    margin: 0em 1em;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: bold;
    border: 1px solid #999;
    border-radius: 5px;
    color: #666;
  }
  &.levelbar{
    display: block;
    width: 100%;
    border: 1px solid #666;
    border-radius: 0.5em;
    padding: 0;
    font-size: 0.7rem;
    text-align: left;
    position: relative;
    color: #666;
    > .progress{
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
      width: 30%;
      height: 100%;
      background: rgba(0,0,0,0.1);
    }
    > .value{
      display: block;
      height: 100%;
      padding-left: 3em;
      > span{
        font-size: 1.2em;
        line-height: 1.2;
        transform: translateY(-1px);
      }
      .MuiSvgIcon-root{
        font-size: 1.2em;

        position: absolute !important;
        top: 50%;
        right: -1.2em;
        z-index: 2;
        transform: translateY(-50%);
      }
    }
  }
}



.MuiAvatar-root{
  &.large{
    width: 60px;
    height: 60px;
    .MuiSvgIcon-root{
      width: 50px;
      height: 50px;
    }
  }
}



.completed{
  font-style: italic;
  text-decoration: line-through;
  opacity: 0.8;
}


.background{
  background: rgba(255,255,255,0.8);
}